import { XMarkIcon } from "@heroicons/react/20/solid";
import Image from "next/image";
import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { forwardRef } from "react";
import { cn } from "utils/cn";

export const SpotlightHelpInfo = forwardRef<
  HTMLDivElement,
  {
    width?: number;
    left?: number;
    helpStepIndex?: number;
    totalSteps?: number;
    children: ReactNode;
    arrowDirection: "left" | "up";
    setHelpStepIndex?: (index: number) => void;
    handleClose: () => void;
    className?: string;
  } & ComponentPropsWithoutRef<"div">
>(function SpotlightHelpInfo(
  {
    width = 264,
    left,
    totalSteps = 1,
    helpStepIndex,
    children,
    arrowDirection,
    setHelpStepIndex,
    handleClose,
    className
  },
  ref
) {
  return (
    <>
      <div
        className={cn(
          "pointer-events-auto absolute z-50 w-full max-w-xs rounded-lg border border-gray-300 bg-white shadow-md",
          "before:content-[''] after:content-['']",
          arrowDirection === "up" && [
            "after:absolute after:bottom-full after:left-1/2 after:-ml-2 after:border-[8px] after:border-transparent after:border-b-white",
            "before:absolute before:bottom-full before:left-1/2 before:-ml-[9px] before:border-[9px] before:border-transparent before:border-b-gray-300"
          ],
          arrowDirection === "left" && [
            "after:absolute after:right-full after:top-1/2 after:-mt-2 after:border-[8px] after:border-transparent after:border-r-white",
            "before:absolute before:right-full before:top-1/2 before:-mt-[9px] before:border-[9px] before:border-transparent before:border-r-gray-300"
          ],
          className
        )}
        style={{
          maxWidth: width,
          ...(left && { left })
        }}
        ref={ref}
      >
        <div className="arrow_box relative p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt-0.5">
              <Image
                className="h-10 w-10 rounded-full"
                src="/images/ed.png"
                alt="Ed From Pembee"
                width={40}
                height={40}
              />
            </div>
            <div className="ml-3 w-0 flex-1">
              <p className="text-sm font-medium text-gray-900">
                Ed from Pembee
              </p>
              <div className="mt-1 text-sm text-gray-500">{children}</div>
              <div className="flex gap-5">
                {helpStepIndex !== undefined &&
                  helpStepIndex < totalSteps - 1 && (
                    <a
                      className="mt-2 cursor-pointer text-sm font-medium text-indigo-600 hover:text-indigo-900"
                      onClick={() => setHelpStepIndex?.(helpStepIndex + 1)}
                    >
                      Next
                    </a>
                  )}
                <a
                  className="mt-2 cursor-pointer text-sm font-medium text-gray-500 hover:text-gray-700"
                  onClick={handleClose}
                >
                  Dismiss
                </a>
              </div>
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                type="button"
                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={handleClose}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
