import { renderActivityDateString } from "helpers/helpers";
import type { Activity } from "types/model/activity";
import type { Client } from "types/model/client";

export const ActivityRestrictedSessions = ({
  activitySessionIds,
  allActivitySessions,
  client
}: {
  activitySessionIds: string[];
  allActivitySessions: Activity<string>[];
  client: Client;
}) => {
  const activitySessionsSelected = allActivitySessions.filter(activitySession =>
    activitySessionIds.includes(activitySession._id)
  );

  return (
    <div className="text-xs text-gray-400">
      {/* In case all restricted sessions have now been deleted: */}
      {activitySessionsSelected.length === 0 && (
        <div className="text-red-500">Not enabled for any sessions.</div>
      )}
      {activitySessionsSelected.map(activitySession => (
        <div key={activitySession._id}>
          {renderActivityDateString({
            activityDate: activitySession.date,
            dateOnly: false,
            timeOnly: false,
            timeZone: client.timeZone
          })}
        </div>
      ))}
    </div>
  );
};
